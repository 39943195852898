import { useMemo, useState, useCallback, useEffect, useLayoutEffect, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { updateApiHeadersAuthToken } from 'apis/apiHelper';
import { postLogin, postVerify } from 'apis/auth';
import { useIsLoadings } from 'hooks/general';
import { checkIsUserLoggedIn, getAuthToken, getUser, updateUserAuthObj, deleteUserAuthObj } from 'utils/auth';

import { MOBILE_MAX_WIDTH } from 'utils/constants';
import { errorHandler, guard } from 'utils/general';

import { getHomeRoute, getLoginRoute } from 'utils/routes';

const ContextRoot = createContext();

/* ================================================ Local Functions ================================================ */
// ================================================ Color
const useTheme = () => {
  const originalTheme = useMemo(
    () => ({
      color: {
        primary: '#f32835',
        lightPrimary: '#f8969e',
        lighterPrimary: '#fae7e9',
        secondary: '#2b2c42',
        lightSecondary: '#767891',
        tertiary: '#3c3f51',
        lightTertiary: '#606476',
        black: '#000000',
        grey: '#dfe3e7',
        lightGrey: '#f4f5f7',
        darkGrey: '#8490a4',
        green: '#52C41A',
        red: '#f32835',
        white: '#FFFFFF',
        borderColor: '#E9E9E9'
      },
      fontFamily: {
        title: "Pacifico, 'cursive'"
      },
      fontWeight: {
        light: 200,
        regular: 400,
        bold: 600,
        bolder: 700
      },
      spacing: {
        xxs: '8px',
        xs: '12px',
        regular: '16px',
        md: '24px',
        lg: '32px',
        xl: '40px',
        xxl: '48px',
        xxxl: '60px'
      },
      borderRadius: {
        regular: '8px'
      }
    }),
    []
  );
  const [theme] = useState(originalTheme);

  return theme;
};

// ================================================ Global Items
// ----------------------------------------- Window Size
const useWindowSize = () => {
  const [isMobile, setIsMobile] = useState([0, 0]);

  const checkIsMobile = useCallback(() => {
    setIsMobile(window.innerWidth <= MOBILE_MAX_WIDTH);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', checkIsMobile);
    checkIsMobile();
    return () => window.removeEventListener('resize', checkIsMobile);
  }, [checkIsMobile]);

  return { isMobile };
};

// ----------------------------------------- User
const useUser = history => {
  const [user, setUser] = useState(undefined);
  const [token, setToken] = useState('');
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const isUserLoggedIn = useMemo(() => guard(() => !!user.username, false), [user]);

  const updateUserAndTokenWithAuth = useCallback(() => {
    const storedToken = getAuthToken();
    const storedUser = getUser();
    setUser(storedUser);
    setToken(storedToken);
  }, []);

  const handleOnLogin = useCallback(
    ({ username, password }) => {
      return postLogin(username, password)
        .then(userData => {
          updateUserAuthObj(userData);
          setUser(userData.user);
          setToken(userData.token);
          updateApiHeadersAuthToken();

          history.push(getHomeRoute().path);

          return userData;
        })
        .catch(errorHandler);
    },
    [history]
  );

  const handleOnLogout = useCallback(() => {
    deleteUserAuthObj();
    setUser();
    setToken('');
    updateApiHeadersAuthToken();

    history.replace(getLoginRoute().path);
  }, [history]);

  useEffect(() => {
    const mountUserData = async () => {
      setIsLoadingUser(true);

      if (checkIsUserLoggedIn()) {
        updateApiHeadersAuthToken();
        await postVerify()
          .then(() => {
            updateUserAndTokenWithAuth();
          })
          .catch(() => {
            message.error('Your session has expired. Please log in again.');
            handleOnLogout();
          });
      }

      setIsLoadingUser(false);
    };

    mountUserData();
  }, [updateUserAndTokenWithAuth, handleOnLogout]);

  return { isLoadingUser, user, token, isUserLoggedIn, onLogin: handleOnLogin, onLogout: handleOnLogout };
};

/* ================================================ Main Component ================================================ */
export const ContextRootProvider = ({ children }) => {
  const theme = useTheme();
  const history = useHistory();

  const { isMobile } = useWindowSize();

  const { isLoadingUser, user, token, isUserLoggedIn, onLogin, onLogout } = useUser(history);
  const { isLoading } = useIsLoadings([isLoadingUser]);

  return (
    <ContextRoot.Provider
      value={{
        propsContextRoot: { isMobile, isLoading, user, token, isUserLoggedIn, onLogin, onLogout }
      }}
    >
      <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
    </ContextRoot.Provider>
  );
};

export const withContextRoot = Component => {
  const ContextRootComponent = props => (
    <ContextRoot.Consumer>{({ propsContextRoot }) => <Component propsContextRoot={propsContextRoot} {...props} />}</ContextRoot.Consumer>
  );
  return ContextRootComponent;
};
