import { useCallback, useMemo } from 'react';
import { Badge, Button, Col, Row, Skeleton, Steps } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { useFetchTodayDeliveryOrderByOrderNumber, useFetchPastDeliveryOrdersByOrderNumber } from 'apis/deliveryOrder';

import TextWithUrl from 'components/_local/TextWithUrl/TextWithUrl';

import { useFetchConstant } from 'hooks/constants';

import BgImg from 'images/hometaste-order-tracking-background.png';

import { checkIsArrayNotEmpty, checkIsObjectNotEmpty } from 'utils/general';
import { constructConstantLabel } from 'utils/constants';

import {
  AcceptLineBreakRemarksText,
  BgDeliveryImgContainer,
  BgDeliveryImg,
  ContentContainer,
  DODAddOnsText,
  DODContainer,
  DODNoDeliveryPlaceholder,
  DODTitle,
  DODTitleDesc,
  FTDContactUsLink,
  FTDRemarksLabel,
  FTDTitle,
  HeaderRow,
  PastDeliveryContainer,
  PastDeliveryOrderCard,
  ProgressContainer,
  RootContainer,
  RDAddrLabel,
  RDRemarksLabel,
  RDVehiclePlateNo,
  RDVehicleType,
  StepTrackingDesc,
  StepTrackingText,
  TrackingIconDot
} from './OrderStatusTracking.styles';

const { Step } = Steps;

/* ================================================ Local Functions =================================================== */
const useOrderTrackingDetails = orderNumber => {
  const { isLoading: isLoadingDeliveryOrderStatuses, options: deliveryOrderStatuses, constantObject: deliveryOrderStatusesConst } = useFetchConstant(
    'deliveryOrderStatuses'
  );

  const _postProcessPastDeliveryOrders = useCallback(
    data => {
      const STATUS_RIBBON_COLORS =
        !isLoadingDeliveryOrderStatuses && checkIsObjectNotEmpty(deliveryOrderStatusesConst)
          ? {
              [deliveryOrderStatusesConst.COMPLETED.code]: 'green',
              [deliveryOrderStatusesConst.FAIL.code]: '#3c3f51',
              [deliveryOrderStatusesConst.CANCELLED.code]: '#f32835'
            }
          : {};

      const pastDeliveries = checkIsArrayNotEmpty(data) ? data : [];
      return pastDeliveries.map(formattedDelivery => ({
        ...formattedDelivery,
        ribbonText: constructConstantLabel(deliveryOrderStatuses, formattedDelivery.status),
        ribbonColor: STATUS_RIBBON_COLORS[formattedDelivery.status] || '#3c3f51'
      }));
    },
    [isLoadingDeliveryOrderStatuses, deliveryOrderStatusesConst, deliveryOrderStatuses]
  );

  const { data: deliveryOrder, isLoading: isLoadingTodayDeliveryOrder } = useFetchTodayDeliveryOrderByOrderNumber(orderNumber);
  const { data: pastDeliveryOrders, isLoading: isLoadingPastDeliveryOrders } = useFetchPastDeliveryOrdersByOrderNumber(orderNumber, {
    postProcessFunc: _postProcessPastDeliveryOrders,
    shouldFetch: checkIsObjectNotEmpty(deliveryOrderStatusesConst)
  });

  const getCurrentTrackingProgressDot = () => {
    const currentDeliveryOrderStatus = checkIsObjectNotEmpty(deliveryOrder) ? deliveryOrder.status : '';
    const isInternalRiderAssigned = checkIsObjectNotEmpty(deliveryOrder) && deliveryOrder.rider && deliveryOrder.rider.isInternal;

    if (!isInternalRiderAssigned) {
      return -1;
    }

    if (checkIsObjectNotEmpty(deliveryOrderStatusesConst)) {
      switch (currentDeliveryOrderStatus) {
        case deliveryOrderStatusesConst.NEW.code:
        case deliveryOrderStatusesConst.ASSIGNED.code:
          return 0;
        case deliveryOrderStatusesConst.PICKUP.code:
        case deliveryOrderStatusesConst.DELIVERING.code:
          return 1;
        case deliveryOrderStatusesConst.COMPLETED.code:
          return 2;
        default:
          return -1;
      }
    }
    return -1;
  };

  return {
    todayDeliveryOrder: deliveryOrder,
    pastDeliveryOrders,
    isLoadingTodayDeliveryOrder,
    isLoadingPastDeliveryOrders,
    getCurrentTrackingProgressDot
  };
};

/* ================================================ Local Components ===================================================*/
const RiderDetails = ({ rider = {}, address, adminRemarks, riderRemarks, orderNumber }) => {
  const { name, contact, isInternal = true, vehicle = {} } = rider;
  const { isLoading: isLoadingRiderVehicleTypes, options: riderVehicleTypes } = useFetchConstant('riderVehicleTypes');

  const handleOnWABtnClick = () => {
    const message = `Hi, can I get the tracking link for my order ${encodeURIComponent('#')}${orderNumber}? Thanks!`;
    window.open(`https://api.whatsapp.com/send?phone=60386053508&text=${message}`);
  };

  return isInternal ? (
    <div>
      {isLoadingRiderVehicleTypes ? (
        <Skeleton active />
      ) : (
        <RDVehicleType>
          By {constructConstantLabel(riderVehicleTypes, vehicle.type)}
          {vehicle.model ? ` - ${vehicle.model}` : ''}
        </RDVehicleType>
      )}
      <RDVehiclePlateNo>{vehicle.plateNumber}</RDVehiclePlateNo>
      <h4>Rider: {name && contact ? `${name}, ${contact}` : '-'}</h4>
      <h4>
        <RDAddrLabel>Deliver to: </RDAddrLabel>
        <br />
        {address}
      </h4>
      {riderRemarks && (
        <>
          <br />
          <RDRemarksLabel>Rider's message:</RDRemarksLabel>
          <i>
            <TextWithUrl text={riderRemarks} />
          </i>
        </>
      )}
      {adminRemarks && (
        <>
          <br />
          <RDRemarksLabel>Message to you:</RDRemarksLabel>
          <i>
            <TextWithUrl text={adminRemarks} />
          </i>
        </>
      )}
    </div>
  ) : (
    <div>
      <h2>
        <b>We prioritize your order.</b>
      </h2>
      <i>
        <TextWithUrl
          text={
            adminRemarks ||
            'Your order is being delivered by Lalamove / other external delivery rider. Please reach out to us to get your tracking link.'
          }
        />
      </i>
      <Button type="text" onClick={() => handleOnWABtnClick()} style={{ padding: 4, marginTop: -24 }}>
        <WhatsAppOutlined style={{ color: '#3dc14f' }} />
        Whatsapp us
      </Button>
    </div>
  );
};

const FailToDeliverDetails = ({ adminRemarks }) => {
  const _handleOnClickContactUs = () => {
    window.open('https://api.whatsapp.com/send?phone=60386053508', '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <FTDTitle>We can't deliver your order.</FTDTitle>
      <p>
        Please{' '}
        <FTDContactUsLink type="link" onClick={_handleOnClickContactUs}>
          contact our customer service
        </FTDContactUsLink>{' '}
        for further information
      </p>
      <br />
      <FTDRemarksLabel>Message to you:</FTDRemarksLabel>
      <AcceptLineBreakRemarksText>
        <i>{adminRemarks || 'We are sorry for your delivery. Please reach out to us to understand more about this.'}</i>
      </AcceptLineBreakRemarksText>
    </div>
  );
};

const NoteToCustomer = ({ adminRemarks }) => {
  const _handleOnClickContactUs = () => {
    window.open('https://api.whatsapp.com/send?phone=60386053508', '_blank', 'noopener,noreferrer');
  };

  return (
    <div>
      <FTDRemarksLabel>Message to you:</FTDRemarksLabel>
      <AcceptLineBreakRemarksText>
        <i>{adminRemarks || 'We are sorry for your delivery. Please reach out to us to understand more about this.'}</i>
      </AcceptLineBreakRemarksText>
      <p>
        Please{' '}
        <FTDContactUsLink type="link" onClick={_handleOnClickContactUs}>
          contact our customer service
        </FTDContactUsLink>{' '}
        for further information
      </p>
    </div>
  );
};

const SessionTitle = ({ orderNumber, date, session }) => {
  return !!orderNumber ? (
    <>
      <DODTitle>#{orderNumber}</DODTitle>
      <DODTitleDesc>
        {date}, {session}
      </DODTitleDesc>
    </>
  ) : (
    <DODTitle>
      {date}, {session}
    </DODTitle>
  );
};

const DeliveryOrderDetails = ({ isLoading: isDeliveryOrderLoading, isCurrent = false, deliveryOrder = {}, orderNumber }) => {
  const { date, orderItems, session, rider, recipient, status, adminRemarks, riderRemarks } = deliveryOrder;

  const { isLoading: isLoadingMealSessions, options: mealSessions, constantObject: mealSessionsConst } = useFetchConstant('mealSessions');
  const { isLoading: isLoadingDeliveryOrderStatuses, constantObject: deliveryOrderStatusesConst } = useFetchConstant('deliveryOrderStatuses');

  const isLoading = isDeliveryOrderLoading || isLoadingMealSessions || isLoadingDeliveryOrderStatuses;

  const sessionTitle = useMemo(() => {
    const currentDate = moment().format('YYYY-MM-DD');
    const currentTime = moment().format('HHmm');
    if (!isLoadingMealSessions && checkIsObjectNotEmpty(mealSessionsConst) && isCurrent) {
      switch (true) {
        case currentTime >= mealSessionsConst.LUNCH.endTime:
          return <SessionTitle orderNumber={orderNumber} date={currentDate} session="Dinner" />;
        case currentTime < mealSessionsConst.LUNCH.endTime:
        default:
          return <SessionTitle orderNumber={orderNumber} date={currentDate} session="Lunch" />;
      }
    } else {
      return <SessionTitle date={date} session={constructConstantLabel(mealSessions, session || '')} />;
    }
  }, [isLoadingMealSessions, mealSessionsConst, isCurrent, orderNumber, date, mealSessions, session]);

  const showRiderDetails = useMemo(
    () =>
      checkIsObjectNotEmpty(rider) &&
      checkIsObjectNotEmpty(deliveryOrderStatusesConst) &&
      (status === deliveryOrderStatusesConst.COMPLETED.code ||
        status === deliveryOrderStatusesConst.DELIVERING.code ||
        status === deliveryOrderStatusesConst.PICKUP.code ||
        status === deliveryOrderStatusesConst.ASSIGNED.code),
    [rider, deliveryOrderStatusesConst, status]
  );
  const showFailToDeliverDetails = useMemo(
    () =>
      checkIsObjectNotEmpty(deliveryOrderStatusesConst) &&
      (status === deliveryOrderStatusesConst.CANCELLED.code ||
        status === deliveryOrderStatusesConst.REPORTED_INCIDENT.code ||
        status === deliveryOrderStatusesConst.FAIL.code),
    [status, deliveryOrderStatusesConst]
  );
  const showRemarksOnly = useMemo(
    () => checkIsObjectNotEmpty(deliveryOrderStatusesConst) && status === deliveryOrderStatusesConst.NEW.code && !!adminRemarks,
    [adminRemarks, deliveryOrderStatusesConst, status]
  );

  return (
    <DODContainer isCurrentSection={isCurrent}>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 16]} style={{ margin: 0 }}>
          {checkIsObjectNotEmpty(deliveryOrder) ? (
            <>
              <Col span={24} md={12} lg={16} xl={14}>
                {sessionTitle}
                <div>
                  {orderItems &&
                    orderItems.map(orderItem => {
                      const shouldDisplayExtraOption =
                        checkIsArrayNotEmpty(orderItem.comboOptionLabels) || checkIsArrayNotEmpty(orderItem.addOnLabels);

                      return (
                        <p key={orderItem.name}>
                          <span>{orderItem.name}</span>
                          {shouldDisplayExtraOption && <br />}
                          {checkIsArrayNotEmpty(orderItem.comboOptionLabels) &&
                            orderItem.comboOptionLabels.map((comboOptionLabel, index) => {
                              return (
                                <DODAddOnsText key={index}>
                                  Option {index + 1}: {comboOptionLabel}{' '}
                                </DODAddOnsText>
                              );
                            })}
                          {checkIsArrayNotEmpty(orderItem.addOnLabels) && <DODAddOnsText>Add-ons: {orderItem.addOnLabels.join(', ')}</DODAddOnsText>}
                        </p>
                      );
                    })}
                </div>
              </Col>
              {(showRiderDetails || showFailToDeliverDetails || showRemarksOnly) && (
                <Col span={24} md={12} lg={8} xl={10}>
                  {showRiderDetails && (
                    <RiderDetails
                      rider={rider}
                      address={recipient && recipient.address}
                      adminRemarks={adminRemarks}
                      riderRemarks={riderRemarks}
                      orderNumber={orderNumber}
                    />
                  )}
                  {!showRiderDetails && showFailToDeliverDetails && <FailToDeliverDetails adminRemarks={adminRemarks} />}
                  {showRemarksOnly && <NoteToCustomer adminRemarks={adminRemarks} />}
                </Col>
              )}
            </>
          ) : (
            <Col span={24}>
              {sessionTitle}
              <DODNoDeliveryPlaceholder>
                You have no delivery for this session. <br /> Come back again!
              </DODNoDeliveryPlaceholder>
            </Col>
          )}
        </Row>
      )}
    </DODContainer>
  );
};

/* =================================================== Main Component ================================================== */
const OrderStatusTracking = () => {
  const { orderNumber } = useParams();
  const { todayDeliveryOrder, pastDeliveryOrders, isLoadingTodayDeliveryOrder, getCurrentTrackingProgressDot } = useOrderTrackingDetails(orderNumber);

  const _constructArrivalEstimation = () => {
    const DEFAULT_BUFFER_TIME_IN_MINS = 20;

    if (checkIsObjectNotEmpty(todayDeliveryOrder)) {
      const { deliveryDetails = {} } = todayDeliveryOrder;
      const { estimatedArrivalTime, expectedArrivalStartTime, expectedArrivalEndTime } = deliveryDetails;

      let estArrivalStartTime = moment(expectedArrivalStartTime);
      let estArrivalEndTime = moment(expectedArrivalEndTime);

      if (estimatedArrivalTime) {
        estArrivalStartTime = moment(estimatedArrivalTime);
        estArrivalEndTime = moment(estimatedArrivalTime).add(DEFAULT_BUFFER_TIME_IN_MINS, 'minutes');
      }
      return estArrivalStartTime && estArrivalEndTime
        ? `${moment(estArrivalStartTime).format('h:mma')} - ${moment(estArrivalEndTime).format('h:mma')}`
        : '...in calculating';
    }
  };

  return (
    <RootContainer>
      <HeaderRow>
        <Col>
          <img src={'https://hometaste.my/wp-content/uploads/2021/01/Hometaste-Logo-01.png'} width="200" height="auto" alt="Hometaste" />
        </Col>
      </HeaderRow>
      <ContentContainer>
        <ProgressContainer>
          <Steps
            progressDot={(_, { index, status, title, description }) => {
              return <TrackingIconDot status={status} />;
            }}
            current={getCurrentTrackingProgressDot()}
            responsive="true"
          >
            <Step
              disabled
              title={<StepTrackingText>In the kitchen</StepTrackingText>}
              description={<StepTrackingDesc>Preparing for delivery</StepTrackingDesc>}
            />
            <Step
              disabled
              title={<StepTrackingText>On the way</StepTrackingText>}
              description={
                <StepTrackingDesc>
                  ETA <br />
                  {_constructArrivalEstimation()}
                </StepTrackingDesc>
              }
            />
            <Step disabled title={<StepTrackingText>Enjoy your meal!</StepTrackingText>} />
          </Steps>
        </ProgressContainer>
        <DeliveryOrderDetails isCurrent orderNumber={orderNumber} deliveryOrder={todayDeliveryOrder} isLoading={isLoadingTodayDeliveryOrder} />
        <BgDeliveryImgContainer>
          <BgDeliveryImg src={BgImg} alt="Hometaste delivery order tracking system" width="100%" height="auto" />
        </BgDeliveryImgContainer>

        {checkIsArrayNotEmpty(pastDeliveryOrders) && (
          <PastDeliveryContainer>
            <h1>
              <b>Past Delivery for #{checkIsArrayNotEmpty(pastDeliveryOrders) && pastDeliveryOrders[0].customerOrderNumber}</b>
            </h1>
            {pastDeliveryOrders.map(pastDeliveryOrder => (
              <PastDeliveryOrderCard key={pastDeliveryOrder._id}>
                <Badge.Ribbon text={pastDeliveryOrder.ribbonText} color={pastDeliveryOrder.ribbonColor}>
                  <DeliveryOrderDetails deliveryOrder={pastDeliveryOrder} />
                </Badge.Ribbon>
              </PastDeliveryOrderCard>
            ))}
          </PastDeliveryContainer>
        )}
      </ContentContainer>
    </RootContainer>
  );
};

export default OrderStatusTracking;
