const constructRoute = (path, roles) => ({ path, roles });

/** =========================================== Error Routes =========================================== */
export const get403Route = () => constructRoute('/403');
export const get404Route = () => constructRoute('/404');

/** =========================================== Login Logout Routes =========================================== */
export const getLoginRoute = () => constructRoute('/login');
export const getLogoutRoute = () => constructRoute('/logout');

/** =========================================== Internal Routes =========================================== */
export const getRootRoute = () => constructRoute('/');
export const getAuthFailRoute = () => constructRoute('/auth-fail', ['roleThatDoNotExists']);

export const getHomeRoute = () => constructRoute('/home');

// =========================================== Order
export const getOrderRoute = () => constructRoute('/order');

// =========================================== Order Status Tracking
export const getOrderStatusTrackingRoute = (orderNumber = ':orderNumber') => constructRoute(`/order/${orderNumber}/tracking`);
