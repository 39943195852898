import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';

import { getLoginRoute } from 'utils/routes';
import placeHolderImage from './images/403.svg';

import './Forbidden403.css';
import styles from './page403.module.css';

const Forbidden403 = ({ propsContextRoot: { onLogout } = {} }) => {
  return (
    <Row className={styles.errorRowForException}>
      <Col span={24} md={10} className={styles.errorColumnForException}>
        <img src={placeHolderImage} alt="forbidden-page" className={styles.image} />
      </Col>
      <Col span={0} md={1} />
      <Col span={24} md={11} className={styles.errorColumnForException}>
        <div className={styles.errorColumnContent}>
          <span className={styles.errorTitle}>403 Forbidden</span>
          <span className={styles.errorSubtitle}>
            We are sorry, you do not have access to this page or resource. Please contact the administrator for assistance. If this happens
            continually, please try to{' '}
            <Link onClick={onLogout} to={getLoginRoute().path}>
              log in again
            </Link>
            .
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default withContextRoot(Forbidden403);
