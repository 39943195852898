import api from './apiHelper';
import { useCustomQuery } from 'hooks/reactQuery';

/* ---------------------------------- Get ---------------------------------------- */
export const useFetchTodayDeliveryOrderByOrderNumber = (customerOrderNumber, { shouldFetch = true, postProcessFunc } = {}) => {
  const getTodayDeliveryOrderByOrderNumber = async (key, customerOrderNumber) => {
    return api.get(`/delivery-order/${customerOrderNumber}`);
  };

  return useCustomQuery('getTodayDeliveryOrder', [customerOrderNumber], getTodayDeliveryOrderByOrderNumber, {
    shouldFetch: !!customerOrderNumber && shouldFetch,
    postProcessFunc
  });
};

export const useFetchPastDeliveryOrdersByOrderNumber = (customerOrderNumber, { shouldFetch = true, postProcessFunc } = {}) => {
  const getPastDeliveryOrderByOrderNumber = async (key, customerOrderNumber) => {
    return api.get(`/delivery-order/${customerOrderNumber}/history`);
  };

  return useCustomQuery('getPastDeliveryOrderByOrderNumber', [customerOrderNumber], getPastDeliveryOrderByOrderNumber, {
    shouldFetch: !!customerOrderNumber && shouldFetch,
    postProcessFunc
  });
};
