// ================================================= GET
// ------------------------------------------------- General
const getAuthObj = () => {
  const authFromLocalStorage = window.localStorage.getItem('auth');
  if (authFromLocalStorage) {
    return JSON.parse(authFromLocalStorage);
  }
  return {};
};

export const getUser = () => {
  const authObj = getAuthObj();
  return authObj.user || {};
};

export const getAuthToken = () => {
  const authObj = getAuthObj();
  return authObj.token || '';
};

// ------------------------------------------------- Check
export const checkIsUserLoggedIn = () => {
  const authObj = getAuthObj();
  return Object.keys(authObj).length > 0;
};

// ================================================= UPDATE
export const updateUserAuthObj = userAuthObj => {
  window.localStorage.setItem('auth', JSON.stringify(userAuthObj));
};

// ================================================= DELETE
export const deleteUserAuthObj = () => {
  window.localStorage.removeItem('auth');
};
