import styled from '@emotion/styled';
import { Button, Row } from 'antd';

const _trackingStatusDotColor = {
  wait: props => props.theme.color.grey,
  process: props => props.theme.color.primary,
  finish: props => props.theme.color.primary,
  error: props => props.theme.color.tertiary
};

export const RootContainer = styled.div`
  margin: 0 auto;
  max-width: 1050px; // Follow current hometaste.my
`;

export const HeaderRow = styled(Row)`
  padding: ${props => props.theme.spacing.regular};
`;

export const ContentContainer = styled.div`
  padding: 32px 16px;
  width: 100%;
`;

export const ProgressContainer = styled.div`
  padding: 0px 16px;

  @media (min-width: 532px) {
    // Use 532px due to the responsive breakpoint for Steps by Antd.
    padding: 24px;
  }
`;

export const TrackingIconDot = styled.div`
  border-radius: 50%;
  background-color: ${props => _trackingStatusDotColor[props.status]};
  height: 32px;
  margin-left: -11px;
  margin-top: -11px;
  width: 32px;
`;

export const StepTrackingText = styled.p`
  margin: -4px 0 0 8px;
  /* font-weight: bold; */

  @media (min-width: 532px) {
    // Use 532px due to the responsive breakpoint for Steps by Antd.
    margin: 8px 0 0 0;
  }
`;

export const StepTrackingDesc = styled.p`
  margin: -4px 0 16px 8px;
  font-weight: bold;

  @media (min-width: 532px) {
    // Use 532px due to the responsive breakpoint for Steps by Antd.
    margin: 0;
  }
`;

export const DODContainer = styled.div`
  border-radius: 16px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding: ${props => (props.isCurrentSection ? '24px 24px 80px 24px' : '24px')};
  width: 100%;
`;

export const DODTitle = styled.h1`
  font-weight: bold;
  margin-bottom: 4px;
`;

export const DODTitleDesc = styled.h2`
  color: ${props => props.theme.color.lightSecondary};
`;

export const DODAddOnsText = styled.span`
  display: block;
  margin-bottom: 4px;
  color: #8c8c8c;
`;

export const DODNoDeliveryPlaceholder = styled.h2`
  margin: 32px 0;
  text-align: center;
  color: #8c8c8c;
`;

export const RDVehicleType = styled.h4`
  margin-bottom: 4px;
  color: ${props => props.theme.color.lightSecondary};
`;

export const RDVehiclePlateNo = styled.h1`
  margin-bottom: 0px;
  font-weight: ${props => props.theme.fontWeight.bold};
`;

export const RDAddrLabel = styled.span`
  font-weight: ${props => props.theme.fontWeight.regular};
`;

export const RDRemarksLabel = styled.p`
  margin-bottom: 4px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: #8c8c8c;
`;

export const FTDTitle = styled.h2`
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => props.theme.color.primary};
`;

export const FTDContactUsLink = styled(Button)`
  margin: 0;
  padding: 0 0 4px 0;
`;

export const FTDRemarksLabel = styled.p`
  margin-bottom: 4px;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: #8c8c8c;
`;

export const AcceptLineBreakRemarksText = styled.p`
  white-space: pre-wrap;
`;

export const BgDeliveryImgContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const BgDeliveryImg = styled.img`
  align-self: center;
  max-width: 888px;
  margin-top: -80px;
`;

export const PastDeliveryContainer = styled(Row)`
  margin-top: ${props => props.theme.spacing.xxl};
`;

export const PastDeliveryOrderCard = styled.div`
  width: 100%;
  margin-bottom: ${props => props.theme.spacing.regular};
`;
