import { Col, Input } from 'antd';
import { useHistory } from 'react-router';

import BgImg from 'images/hometaste-order-tracking-background.png';
import { getOrderStatusTrackingRoute } from 'utils/routes';

import { BgDeliveryImgContainer, BgDeliveryImg, ContentContainer, HeaderRow, RootContainer, SearchContainer } from './OrderStatusSearch.styles';

const { Search } = Input;

const OrderStatusSearch = () => {
  const history = useHistory();

  const _onSearch = value => history.push(getOrderStatusTrackingRoute(value).path);

  return (
    <RootContainer>
      <HeaderRow>
        <Col>
          <img src={'https://hometaste.my/wp-content/uploads/2021/01/Hometaste-Logo-01.png'} width="200" height="auto" alt="Hometaste" />
        </Col>
      </HeaderRow>
      <ContentContainer>
        <SearchContainer>
          <h1>Want to check on your order's delivery progress?</h1>
          <h3 style={{ color: '#8c8c8c' }}>Enter your order number to get current update</h3>
          <Search placeholder="E.g.: 1234567" onSearch={_onSearch} enterButton />
        </SearchContainer>
        <BgDeliveryImgContainer>
          <BgDeliveryImg src={BgImg} alt="Hometaste delivery order tracking system" width="100%" height="auto" />
        </BgDeliveryImgContainer>
      </ContentContainer>
    </RootContainer>
  );
};

export default OrderStatusSearch;
