import { Redirect } from 'react-router-dom';
import { Skeleton } from 'antd';
import PropTypes from 'prop-types';

import { withContextRoot } from 'contexts/ContextRoot/ContextRoot';

import { getOrderRoute } from 'utils/routes';

const orderRoute = getOrderRoute();

const LandingPageRedirect = ({ propsContextRoot: { isLoading: isLoadingContextRoot, isUserLoggedIn } = {} }) => {
  if (!isLoadingContextRoot) {
    if (!isUserLoggedIn) {
      return <Redirect exact from="/" to={orderRoute.path} />;
    } else {
      return <Redirect exact from="/" to={orderRoute.path} />;
    }
  }

  return <Skeleton active />;
};

LandingPageRedirect.propTypes = {
  propsContextRoot: PropTypes.object.isRequired
};

export default withContextRoot(LandingPageRedirect);
