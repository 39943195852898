import axios from 'axios';
import { REACT_APP_API_ENDPOINT } from 'config/env';
import { checkIsUserLoggedIn, getAuthToken } from 'utils/auth';

const FIELDS_SEPERATOR = ',';

/* =============================================================================================================== */
/* ================================================= Default API ================================================= */
/* =============================================================================================================== */
/* =============================================== Exported Functions =============================================== */
export const updateApiHeadersAuthToken = () => {
  api.defaults.headers.Authorization = checkIsUserLoggedIn() ? getAuthToken() : undefined;
};

/* =============================================== Local Functions =============================================== */
// TODO: Might need apiErrorHandlerWithRedirect but since we have react-query we might do that there
const apiErrorHandler = e => {
  if (e.response && e.response.data) {
    if (e.response.data.code) {
      throw e.response.data;
    } else if (e.response.data.message) {
      throw new Error(e.response.data.message);
    } else {
      throw new Error(e.response.data);
    }
  } else if (e.message) {
    throw e;
  } else {
    throw new Error('Unexpected error occured when calling api. Please contact admin.');
  }
};

const constructAndMutateOptions = params => {
  // fields
  if (!!params && !!params.fields) {
    params._fields = params.fields;
    delete params.fields;
  }
};

const mutateParamsArrayToString = params => {
  if (!!params) {
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        const valueInString = value.join(FIELDS_SEPERATOR);
        params[key] = valueInString;
      }
    });
  }
};

/* =============================================== API Actions =============================================== */
const api = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    Authorization: checkIsUserLoggedIn() ? getAuthToken() : undefined
  }
});

api.interceptors.request.use(req => {
  constructAndMutateOptions(req.params);
  mutateParamsArrayToString(req.params);
  return req;
});
api.interceptors.response.use(res => res.data, apiErrorHandler);

export default api;

/* ================================================================================================================ */
/* ================================================= Non Auth API ================================================= */
/* ================================================================================================================ */
export const nonAuthApi = axios.create({
  baseURL: REACT_APP_API_ENDPOINT,
  headers: {
    'Content-Type': 'application/json'
  }
});

nonAuthApi.interceptors.response.use(res => res.data, apiErrorHandler);
