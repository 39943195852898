import { useCallback, useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { errorHandler, guard } from 'utils/general';

/* ================================================ Exported Functions ================================================ */
export const useCustomQuery = (
  key,
  paramsInArray = [],
  apiFunction,
  {
    shouldDisplayError = true,
    prefixErrorMessage = '',
    shouldFetch = true,
    postProcessFunc = returnedData => returnedData,
    onError = error => error,
    ...queryOptions
  } = {}
) => {
  const keysInQuery = useMemo(() => [key, ...paramsInArray], [key, paramsInArray]);

  const { data, isFetching, error, ...returnedParams } = useQuery(keysInQuery, async () => apiFunction(...keysInQuery).then(postProcessFunc), {
    enabled: shouldFetch,
    ...queryOptions
  });
  const { refetch } = useRefetchQuery(keysInQuery);

  useError(shouldDisplayError, error, prefixErrorMessage, onError);

  const returnedData = useMemo(() => data || {}, [data]);

  // isFetching is always true even when it is not fetching.
  return { ...returnedParams, isLoading: isFetching, data: returnedData, error, refetch };
};

export const useCustomPaginatedQuery = (key, { filter, sort, currentPage = 1, limit = 10, extraParams = [] } = {}, apiFunction, queryOptions) => {
  const queryToFetch = useMemo(
    () => ({
      ...(guard(() => Object.keys(filter).length > 0, false) && { filter }),
      ...(sort && { sort: encodeURIComponent(JSON.stringify(sort)) }),
      pagination: encodeURIComponent(JSON.stringify({ limit, currentPage }))
    }),
    [filter, sort, limit, currentPage]
  );

  const { data, ...returnedParams } = useCustomQuery(key, [queryToFetch, ...extraParams], apiFunction, {
    ...queryOptions,
    keepPreviousData: true
  });

  return { ...returnedParams, data };
};

/* ================================================ Local Functions ================================================ */
export const useRefetchQuery = keys => {
  const queryClient = useQueryClient();

  const refetch = useCallback(
    (extraKeys = []) => {
      queryClient.invalidateQueries([...keys, ...extraKeys]);
    },
    [queryClient, keys]
  );

  return { refetch };
};

const useError = (shouldDisplayError, error, prefixErrorMessage, onError) => {
  useEffect(() => {
    if (shouldDisplayError && !!error) {
      errorHandler(error, { prefixErrorMessage });
    }
  }, [shouldDisplayError, error, prefixErrorMessage]);

  useEffect(() => {
    if (!!error) {
      onError(error);
    }
  }, [error, onError]);
};
