import styled from '@emotion/styled';
import { Row } from 'antd';

export const RootContainer = styled.div`
  margin: 0 auto;
  max-width: 1050px; // Follow current hometaste.my
  min-height: 100vh;
`;

export const HeaderRow = styled(Row)`
  padding: ${props => props.theme.spacing.regular};
`;

export const ContentContainer = styled.div`
  padding: 32px 16px;
  width: 100%;

  @media (min-width: 992px) {
    padding: 32px 20%;
  }
`;

export const BgDeliveryImgContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const BgDeliveryImg = styled.img`
  align-self: center;
  max-width: 888px;
  margin-top: -48px;
`;

export const SearchContainer = styled.div`
  border-radius: 16px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  padding: 24px 24px 80px 24px;
  width: 100%;

  @media (min-width: 992px) {
    padding: 48px 48px 96px 48px;
  }
`;
