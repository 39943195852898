import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { QueryClientProvider } from './reactQuery/queryClient';

import { ContextRootProvider } from 'contexts/ContextRoot/ContextRoot';
import LandingPageRedirect from 'components/LandingPageRedirect/LandingPageRedirect';

import Forbidden403 from 'pages/403/Forbidden403';
import NotFound404 from 'pages/404/NotFound404';

import OrderStatusSearch from 'pages/OrderStatusSearch/OrderStatusSearch';
import OrderStatusTracking from 'pages/OrderStatusTracking/OrderStatusTracking';

import { get403Route, get404Route, getRootRoute, getOrderRoute, getOrderStatusTrackingRoute } from 'utils/routes';

import reportWebVitals from './reportWebVitals';
import './index.css';

const rootRoute = getRootRoute();
const forbiddenRoute = get403Route();
const notFoundRoute = get404Route();

const orderRoute = getOrderRoute();
const orderStatusTrackingRoute = getOrderStatusTrackingRoute();

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <QueryClientProvider>
        <Helmet>
          <title>Hometaste - Order Tracking</title>
        </Helmet>
        <Router>
          <ContextRootProvider>
            <Switch>
              {/* // SETUP: For project that doesn't have a landing page, can use LandingPageRedirect */}
              <Route exact path={rootRoute.path} component={LandingPageRedirect} />
              <Route exact path={orderRoute.path} component={OrderStatusSearch} />
              <Route exact path={orderStatusTrackingRoute.path} component={OrderStatusTracking} />
              <Route exact path={forbiddenRoute.path} component={Forbidden403} />
              <Route exact path={notFoundRoute.path} component={NotFound404} />
            </Switch>
          </ContextRootProvider>
        </Router>
      </QueryClientProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
