import { useMemo } from 'react';

/* ================================================= Exported Functions ================================================= */
export const useIsLoadings = isLoadingsInArray => {
  const isLoading = useMemo(() => isLoadingsInArray.reduce((isLoading, currentIsLoading) => isLoading || currentIsLoading, false), [
    isLoadingsInArray
  ]);

  return { isLoading };
};
