// SETUP: Remove mocks and change to API calls
// import api from './apiHelper';

export const postLogin = async (username, password) => {
  return {
    token: 'JWT MOCK TOKEN',
    user: {
      _id: '60796a17d8cf508799215d15',
      name: 'Mock Login User',
      username: 'mock-login-user',
      role: 'admin'
    }
  };
  // return api.post('/auth/login', { username, password })
};

export const postVerify = async () => {
  return {
    status: 200,
    data: {
      message: 'ok'
    }
  };
  // return api.post('/auth/verify');
};
