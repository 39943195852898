import { guard } from 'utils/general';

/* =============================================== Local Constants =============================================== */
// =============================================== Specific Constants - The constants that generally use in one page
// components - Upload
const sizeInB = 1024;

/* =============================================== Exported Constants =============================================== */
// =============================================== General Constants
// Mobile Max Width
export const MOBILE_MAX_WIDTH = 768;

// Date
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_WITH_WEEKDAY_FORMAT = `${DATE_FORMAT}, dddd`;
export const DATE_TIME_FORMAT = `${DATE_FORMAT} h:mma`;
export const MONTH_FORMAT = `YYYY-MM`;
export const TIME_FORMAT = 'HH:mm';
export const DAYS_OF_WEEK = {
  SUNDAY: { value: 0, label: 'Sunday' },
  MONDAY: { value: 1, label: 'Monday' },
  TUESDAY: { value: 2, label: 'Tuesday' },
  WEDNESDAY: { value: 3, label: 'Wednesday' },
  THURSDAY: { value: 4, label: 'Thursday' },
  FRIDAY: { value: 5, label: 'Friday' },
  SATURDAY: { value: 6, label: 'Saturday' }
};

// Time
export const MS_IN_SEC = 1000;
export const MS_IN_MIN = MS_IN_SEC * 60;
export const MS_IN_HOUR = MS_IN_MIN * 60;
export const MS_IN_DAY = MS_IN_HOUR * 24;

// Form Label
export const LABEL_SIZE_XXLARGE = 'xxlarge';
export const LABEL_SIZE_XLARGE = 'xlarge';
export const LABEL_SIZE_LARGE = 'large';
export const LABEL_SIZE_REGULAR = 'regular';
export const LABEL_SIZE_SMALL = 'small';

// =============================================== Specific Constants - The constants that generally use in one page
// components - Upload
export const sizeInKb = 1024 * sizeInB;

export const FILE_STATUS_DONE = 'done';
export const FILE_STATUS_ERROR = 'error';
export const FILE_STATUS_REMOVED = 'removed';

// =============================================== Constants functions
export const constructBooleanOptions = (booleanLabels = ['Yes', 'No']) => {
  const trueLabel = booleanLabels[0];
  const falseLabel = booleanLabels[1];

  return [
    { value: true, label: trueLabel, isTrue: true },
    { value: false, label: falseLabel, isTrue: false }
  ];
};

export const getConstantObject = (constants, value, { isConstantObject, codeKey = 'code' } = {}) => {
  const formattedConstants = isConstantObject ? Object.values(constants) : constants;
  const constantObject = formattedConstants.find(constantObject => constantObject[codeKey] === value);
  return constantObject;
};

export const constructConstantData = (constants, value, returnedDataKey, { isConstantObject, fallbackValue, codeKey } = {}) => {
  const constantObject = getConstantObject(constants, value, { isConstantObject, codeKey });
  return guard(() => constantObject[returnedDataKey], fallbackValue);
};

export const constructConstantLabel = (constants, value, { isConstantObject, fallbackValue, codeKey, labelKey = 'label' } = {}) => {
  return constructConstantData(constants, value, labelKey, { isConstantObject, fallbackValue, codeKey });
};

export const constructBooleanLabel = boolean => {
  return constructConstantLabel(constructBooleanOptions(), boolean, { codeKey: 'isTrue' });
};
